import React from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import {Stack, TableCell, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import StateBadge from 'app/widgets/Shared/StateBadge';
import moment from 'moment';
// import { TimePicker } from '@mui/lab';
// import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
// import {LocalizationProvider} from "@mui/x-date-pickers";
// import {TimePicker} from "@mui/x-date-pickers/TimePicker";

const DragHandle = SortableHandle(() => <DragHandleIcon sx={{cursor: 'grab', verticalAlign: 'middle'}} color="primary"/>);

const DeliveryCell = props => {

    const {delivery, canSort} = props;


    const {code, state, state_name, customer, planned_start, planned_end} = delivery;

    return (
        <TableRow onClick={() => props.onSelect(delivery)} sx={{
                "&:hover": {
                    backgroundColor: "#f5f5f5"
                }
            }}>
            <TableCell width={"5%"} style={{paddingLeft: 0}}>
                <Typography variant={"body1"} mb={0}>{props.stop}</Typography>
            </TableCell>
            <TableCell width={"35%"} style={{paddingLeft: 0}}>
                <Typography variant={"body1"} mb={0}>{code}</Typography>
                <Typography variant={"body1"} mb={0}>{customer?.code} {customer?.name}</Typography>
            </TableCell>
            <TableCell width={"7%"} style={{paddingLeft: 0}}>
                <StateBadge state={state} stateName={state_name} withIcon={false}/>
            </TableCell>
            <TableCell width={"30%"} style={{paddingLeft: 0}}>
                <Typography variant={"body1"} mb={0}>{customer?.location?.short_address}</Typography>
                <Typography variant={"body1"} mb={0}>{customer?.location?.short_zone}</Typography>
            </TableCell>
            <TableCell width={"20%"} style={{paddingLeft: 0}}>
                <Stack
                    direction="row"
                    // justifyContent="flex-end"
                    alignItems="center"
                    spacing={0.5}>
                    <AccessTimeOutlinedIcon/>
                    <Stack>
                        {/* <Typography variant={"body1"} mb={0}>{planned_start ? moment(planned_start).format('DD MMM HH:mm') : '-'}</Typography> */}
                        <Typography variant={"body1"} mb={0}>{planned_end ? moment(planned_end).format('DD MMM HH:mm') : '-'}</Typography>
                    </Stack>
                </Stack>
            </TableCell>
            <TableCell width={"3%"} style={{paddingLeft: 0}}>
                {canSort && <DragHandle />}
            </TableCell>
        </TableRow>
    );
};

export default SortableElement(DeliveryCell);