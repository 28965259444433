import {USE_IMAGE_PLACEHOLDERS} from "./constants/paths";
import _ from "lodash";

export const getAssetPath = (url, size) => {
    if(USE_IMAGE_PLACEHOLDERS) {
        return `https://via.placeholder.com/${size}.png`;
    }

    return url;
};

export const defaultSwalPreferences = (swalObj) => {
    return {
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: toast => {
            toast.addEventListener('mouseenter', swalObj.stopTimer);
            toast.addEventListener('mouseleave', swalObj.resumeTimer);
        }
    }
}

export const geoCoords = strCoords => {
    var out = _.map(_.compact(_.split(strCoords, ";")), (p) => { return {lat: parseFloat(_.split(p, ',')[0]), lng: parseFloat(_.split(p, ',')[1]) } })
    return out
}