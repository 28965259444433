import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import RouteRoundedIcon from '@mui/icons-material/RouteRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';

export const stateInfo = (_state) => {
    if(_state == 'pending') {
        return { color: 'info', icon: <PendingActionsRoundedIcon/> }
    } else if(_state == 'in_progress') {
        return { color: 'warning', icon: <RouteRoundedIcon/> }
    } else if(_state == 'finished') {
        return { color: 'success', icon: <FlagRoundedIcon/> };
    } else {
        return { color: 'secondary', icon: <CalendarMonthRoundedIcon/> };
    }
}