import React, { useState } from 'react';
import { Avatar, Button, Typography, ListItem, ListItemAvatar, ListItemIcon, ListItemText, ListItemButton, IconButton, ButtonGroup} from "@mui/material";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Stack } from "@mui/system";
import _ from 'lodash';

const ConfirmableItemList = ({item, onTrash=() => {}}) => {
    const [showConfirm, setShowConfirm] = useState(false)

    return (
        <ListItem
            secondaryAction={
                <React.Fragment>
                    <Stack
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        spacing={1}>
                            { showConfirm ?
                                <React.Fragment>
                                    <Typography variant={"overline"} color="red">¿Estás seguro?</Typography>
                                    <ButtonGroup variant="contained" size="small" aria-label="small button group">
                                        <Button color="error" onClick={onTrash}>Si</Button>
                                        <Button color="inherit" onClick={() => setShowConfirm(false) }>No</Button>
                                    </ButtonGroup>
                                </React.Fragment>
                            :
                                <IconButton edge="end" aria-label="delete" onClick={() => setShowConfirm(true) }>
                                    <DeleteOutlineOutlinedIcon/>
                                </IconButton>
                            }
                    </Stack>
                </React.Fragment>
            }>
            <ListItemAvatar>
                <Avatar>
                    <AccountCircleOutlinedIcon/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item} />
        </ListItem>
    )
    
};

export default ConfirmableItemList;