import React from "react";
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import ForgotPassword from "../pages/auth/forgot-password";
import ResetPassword from "../pages/auth/reset-password";
import Page from "@jumbo/shared/Page";
import LAYOUT_NAMES from "app/layouts/layouts";

const authRoutes = [
    {
        path: "/auth/login",
        element: <Page component={Login} layout={LAYOUT_NAMES.SOLO_PAGE} />,
    },
    // {
    //     path: "/auth/signup",
    //     element: <Page component={Signup} layout={LAYOUT_NAMES.SOLO_PAGE} />
    // },
    {
        path: "/auth/forgot-password",
        element: <Page component={ForgotPassword} layout={LAYOUT_NAMES.SOLO_PAGE} />
    },
    {
        path: "/auth/reset-password",
        element: <Page component={ResetPassword} layout={LAYOUT_NAMES.SOLO_PAGE} />
    }
];

export default authRoutes;
