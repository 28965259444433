import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TableContainer, Table, TableBody } from "@mui/material";
import _ from "lodash";
import { geoCoords, getAssetPath } from "app/utils/appHelpers";
import Div from "@jumbo/shared/Div";
import { GoogleMap, Marker, Polygon, Polyline, useLoadScript } from "@react-google-maps/api";
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import tripServices from "app/services/trip-services"
import moment from 'moment';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import DeliveryCell from '../../pages/home/DeliveryCell';
import { LoadingButton } from '@mui/lab';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import CustomMarker from '../Markers';
import { GOOGLE_MAPS_API_KEY } from 'app/utils/constants/paths';

const GOOGLE_MAPS_LIBS = ["visualization", "places", "drawing"]

// https://loading.io/icon/set/thjmhy-map-marker

const RouteBuilder = props => {
    const [tripDetails, setTripDetails] = useState(null)
    const [newDeliveries, setNewDeliveries] = useState([])
    const [deliveriesLoading, setDeliveriesLoading] = useState(false)
    const [routeLoading, setRouteLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [originLoc, setOriginLoc] = useState(null)
    const [mapCenter, setMapCenter] = useState({
        lat: -33.51392,
        lng: -70.73039,
    })
    const [selectedDelivery, setSelectedDelivery] = useState(null)

    useEffect(() => {
        if(props.trip) {
            setDeliveriesLoading(true);
            tripServices.getTripDetails(props.trip?.id, '?with_routes=true')
                .then((data) => {
                    setTripDetails(data)
                    setNewDeliveries(data?.deliveries)
                    setOriginLoc(data?.origin_location)
                    if(data?.origin_location) {
                        setMapCenter({
                            lat: data.origin_location.lat,
                            lng: data.origin_location.lng
                        })
                    }
                    setDeliveriesLoading(false);
                });
        }
    }, [])

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: GOOGLE_MAPS_LIBS
    });

    const [canSave, setCanSave] = useState(false)
    const optionsPoly = {
        strokeColor: "#1867D2",
        fillColor: "#02B0FF",        
        strokeOpacity: 0.8,
        strokeWeight: 4,
        fillOpacity: 1,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        zIndex: 1
    };

    const renderMap = () => {
        var last_customer = null;
        var curr_stop = 0;

        return(
            <React.Fragment>
                { (isLoaded && newDeliveries && newDeliveries[0]?.customer?.location?.lat) &&
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: "400px" }}
                        center={mapCenter}
                        zoom={10}>
                        {
                            <React.Fragment>
                                {_.map(newDeliveries, (nd) => {
                                    return(
                                        <Polygon
                                            path={geoCoords(nd.customer?.location?.path)}
                                            key={nd.code}
                                            options={{
                                                strokeColor: "#FF0000",
                                                strokeOpacity: 0.8,
                                                strokeWeight: 2,
                                                fillColor: "#FF0000",
                                                fillOpacity: 0.35
                                            }}
                                        />
                                    )
                                })}
                                {originLoc &&
                                    <Marker
                                        icon={getAssetPath(`${ASSET_IMAGES}/map-markers/base.png`)}
                                        position={{ lat: originLoc.lat, lng: originLoc.lng }}
                                    />
                                }
                                {/* <Polygon
                                    path={geoCoords(nd.customer?.location?.path)}
                                    key={nd.code}
                                    options={{
                                        strokeColor: "#FF0000",
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2,
                                        fillColor: "#FF0000",
                                        fillOpacity: 0.35
                                    }}
                                /> */}
                                {_.map(newDeliveries, (nd) => {
                                    if(last_customer != nd.customer?.id) {
                                        last_customer = nd.customer?.id
                                        curr_stop += 1
                                        return(
                                            <React.Fragment>
                                                <CustomMarker
                                                    id={nd.id}
                                                    lat={nd.customer?.location?.lat}
                                                    lng={nd.customer?.location?.lng}
                                                    kind={nd.state}
                                                    // labelAnchor={{ x: 40 / 2, y: 80 }}
                                                    label={{
                                                        text: `${curr_stop}`,
                                                        color: 'white',
                                                        textAlign: 'center',
                                                        fontWeight: 'bold'
                                                    }}
                                                    zIndex={100} />
                                                <Polyline
                                                    // onLoad={onLoadPoly}
                                                    path={nd.route_path}
                                                    options={optionsPoly}
                                                    />
                                                {nd.receipt && nd.receipt?.lat && nd.receipt?.lng &&
                                                    <Marker
                                                        label={{
                                                            text: `${curr_stop}`,
                                                            color: 'white',
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                            anchor: { x: (20/2) , y: 80 }
                                                        }}
                                                        position={{lat: _.toNumber(nd.receipt.lat), lng: _.toNumber(nd.receipt.lng)}}
                                                    />
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                })}

                                {/* {!currCustomerGeofence &&
                                    <DrawingManager
                                        drawingMode='polygon'
                                        // onLoad={onLoad}
                                        onOverlayComplete={onPolygonComplete}
                                        // options={
                                        //     position: google.maps.ControlPosition.TOP_CENTER,
                                        //     drawingModes: [google.maps.drawing.OverlayType.POLYGON]
                                        // }
                                        defaultOptions={{
                                            drawingControl: true,
                                            drawingControlOptions: {
                                                position: 'TOP_CENTER',
                                                drawingModes: ['polygon']
                                            },
                                            polygonOptions: {editable:true}
                                        }}
                                    />
                                } */}
                            </React.Fragment>
                        }
                    </GoogleMap>
                }
            </React.Fragment>
        )

    }

    const calculateRoute = () => {
        setRouteLoading(true)
        _.map(newDeliveries, (d) => {})
        tripServices.calculateRouteOrder(props.trip?.id)
                .then((data) => {
                    setMapCenter({lat: data.origin_location.lat, lng: data.origin_location.lng})
                    setNewDeliveries(data.deliveries)
                    setRouteLoading(false)
                })
                .catch((err) => {
                    setRouteLoading(false)
                });
    }
    const saveRoute = () => {
        setSaving(true);
        
        let par = { custom_order: _.map(newDeliveries, 'id') }
        tripServices.calculateRouteOrder(props.trip?.id, par)
                .then((data) => {
                    setSaving(false)
                })
                .catch((err) => {
                    setSaving(false)
                });
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        setNewDeliveries(arrayMove(newDeliveries, oldIndex, newIndex));
    };

    const handleDeliverySelect = (delivery) => {
        if(delivery.customer && delivery.customer.location) {
            setMapCenter({lat: delivery.customer.location.lat, lng: delivery.customer.location.lng})
        }
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={props.isOpen}
                onClose={props.onClose}>
                <DialogTitle>
                    <Div sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant={"h2"} color="primary">Instructivo Nº{props.trip?.code} ({moment(props.trip?.ship_date).format('DD MMM')})</Typography>
                        <LoadingButton
                            loading={routeLoading}
                            variant="contained"
                            color='success'
                            size='small'
                            endIcon={<RefreshIcon/>}
                            loadingPosition="end"
                            onClick={() => calculateRoute()}>
                                { routeLoading ? 'Calculando' : 'Optimizar' }
                        </LoadingButton>
                    </Div>
                </DialogTitle>
                <DialogContent>
                    {/* {JSON.stringify(_.map(newDeliveries, 'code'))} */}
                    {!deliveriesLoading ?
                        <React.Fragment>
                            <Typography variant={"h4"}>Planificación de entregas</Typography>
                            {renderMap()}
                            <Deliveries deliveries={newDeliveries} onSortEnd={onSortEnd} useDragHandle={true} _selectedDelivery={selectedDelivery} canSort={props.trip?.state === 'pending'} onSelect={handleDeliverySelect} />
                        </React.Fragment>
                    :
                        <Div sx={{textAlign: 'center', my: 5}}>
                            <CircularProgress color="warning"/>
                        </Div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setCanSave(false); props.onClose() } }>Cerrar</Button>
                    <LoadingButton
                        loading={saving}
                        variant="contained"
                        color={ canSave ? 'success' : 'primary' }
                        size='small'
                        endIcon={<SaveOutlinedIcon/>}
                        loadingPosition="end"
                        onClick={() => saveRoute()}>
                            { saving ? 'Guardando' : 'Guardar' }
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const Deliveries = SortableContainer(({ deliveries, _selectedDelivery, canSort = false, onSelect }) => {
    var last_customer = null;
    var curr_stop = 0;
    return (
        <TableContainer>
            <Table sx={{minWidth: 650}}>
                <TableBody>
                    {deliveries?.map((delivery, index) => {
                        if(last_customer != delivery.customer?.id) {
                            last_customer = delivery.customer?.id
                            curr_stop += 1
                        }
                        return (
                            <DeliveryCell key={delivery.code} index={index} delivery={delivery} selected={_selectedDelivery?.id == delivery.id} canSort={canSort} onSelect={onSelect} stop={curr_stop} />
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
})

export default RouteBuilder;