import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};

authServices.getCurrentUser = async () => {
    const {data} = await jwtAuthAxios.get("/auth");
    return data;
};

//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
authServices.signIn = async (loginCreds) => {
    const data = await jwtAuthAxios.post('/login', { user: loginCreds });
    return data;
};

authServices.restorePassword = async (loginCreds) => {
    const data = await jwtAuthAxios.post('/users/restore_password', { user: loginCreds });
    return data;
};

authServices.resetPassword = async (userCreds) => {
    const data = await jwtAuthAxios.post('/users/reset_password', { user: userCreds });
    return data;
};

export default authServices;