import { defaultSwalPreferences } from "app/utils/appHelpers";
import { API_HOST } from "app/utils/constants/paths";
import jwtAxios from "axios";
import Swal from "sweetalert2";

const jwtAuthAxios = jwtAxios.create({
    baseURL: API_HOST,
    headers: {
        'Content-Type': 'application/json'
    }
});

jwtAuthAxios.interceptors.response.use(
    res => {
        return res;
    },
    err => {
        console.log("Interceptor error")
        console.log(err.response)
        if(err.response) {
            let msg = ''
            if(err.response?.status === 401) {
                msg = 'No autorizado'
            } else if(err.response?.status === 403) {
                msg = 'No tienes suficientes permisos para visualizar esta información'
            } else if(err.response?.data?.error) {
                msg = err.response.data.error
            } else {
                msg = 'Error desconocido'
            }
            Swal.mixin(defaultSwalPreferences(Swal)).fire({
                icon: 'error',
                title: msg,
                background: 'error',
            });
        }
        return Promise.reject(err);
    }
);

export const setAuthToken = (token) => {
    if(token) {
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        sessionStorage.setItem('token', token);
    } else {
        delete jwtAuthAxios.defaults.headers.common['Authorization'];
        sessionStorage.removeItem('token');
    }
};

export const getAuthToken = () => {
    return sessionStorage.getItem("token");
};

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;