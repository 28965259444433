import React from "react";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ListIcon from '@mui/icons-material/List';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import Grid3x3OutlinedIcon from '@mui/icons-material/Grid3x3Outlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import EditNotificationsOutlinedIcon from '@mui/icons-material/EditNotificationsOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';

const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        children: [
            {
                uri: "/trips",
                label: 'sidebar.menuItem.trips',
                type: "nav-item",
                icon: <LocalShippingOutlinedIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/customers",
                label: 'sidebar.menuItem.customers',
                type: "nav-item",
                icon: <PersonPinCircleOutlinedIcon sx={{fontSize: 20}}/>
            },
        ]
    },
    // {
    //     label: 'sidebar.menu.admin',
    //     type: "section",
    //     children: [
    //         {
    //             uri: "/users",
    //             label: 'sidebar.menuItem.users',
    //             type: "nav-item",
    //             icon: <GroupOutlinedIcon sx={{fontSize: 20}}/>
    //         },
    //         {
    //             uri: "/notifications",
    //             label: 'sidebar.menuItem.notifications',
    //             type: "nav-item",
    //             icon: <EditNotificationsOutlinedIcon sx={{fontSize: 20}}/>
    //         },
    //         {
    //             uri: "/logs",
    //             label: 'sidebar.menuItem.logs',
    //             type: "nav-item",
    //             icon: <FeaturedPlayListOutlinedIcon sx={{fontSize: 20}}/>
    //         },
    //     ]
    // },
];

export default menus;
