import React from 'react';
import {Chip} from "@mui/material";
import _ from 'lodash';
import { stateInfo } from './stateInfo';

const StateBadge = ({state, stateName, withIcon=true, sx, variant='filled', clickable, onClick}) => {
    return (
        <Chip
            size={"small"}
            variant={variant}
            color={stateInfo(state).color}
            label={stateName || state}
            icon={withIcon ? stateInfo(state).icon : ''}
            sx={sx}
            clickable={clickable}
            onClick={onClick} />
    );
};

export default StateBadge;