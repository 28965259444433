import jwtAuthAxios from "./auth/jwtAuth";

const deliveryServices = {};

deliveryServices.getDeliveryDetails = async (delivery_id) => {
    const {data} = await jwtAuthAxios.get(`/deliveries/${delivery_id}/details`);
    return data;
};

deliveryServices.getDocsImages = async (delivery_id) => {
    const {data} = await jwtAuthAxios.get(`/deliveries/${delivery_id}/docs_images`);
    return data;
};

deliveryServices.updateDelivery = async (delivery_id, params) => {
    const {data} = await jwtAuthAxios.put(`/deliveries/${delivery_id}`, params);
    return data;
};

export default deliveryServices;