import jwtAuthAxios from "./auth/jwtAuth";

const customerServices = {};

customerServices.getCustomers = async (params) => {
    const {data} = await jwtAuthAxios.get(`/customers${params}`);
    return data;
};

customerServices.getCustomer = async (id) => {
    const {data} = await jwtAuthAxios.get(`/customers/${id}`);
    return data;
};

customerServices.saveRecipients = async (id, params) => {
    const {data} = await jwtAuthAxios.post(`/customers/${id}/recipients`, params);
    return data;
};

customerServices.saveCustomerGeofence = async (id, params) => {
    const {data} = await jwtAuthAxios.post(`/customers/${id}/geofence`, params);
    return data;
};

customerServices.getReceiptsPins = async (id) => {
    const {data} = await jwtAuthAxios.get(`/customers/${id}/receipts_pins`);
    return data;
};

export default customerServices;