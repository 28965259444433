import React from "react";
import Page from "@jumbo/shared/Page";
import Trips from "app/pages/home/Trips";
import Customers from "app/pages/home/Customers";
import Logs from "app/pages/home/Logs";


const mainRoutes = [
    {
        path: "/",
        element: <Page component={Trips} />
    },
    {
        path: "/trips",
        element: <Page component={Trips} />
    },
    {
        path: "/customers",
        element: <Page component={Customers} />
    },
    {
        path: "/logs",
        element: <Page component={Logs} />
    },
];

export default mainRoutes;
