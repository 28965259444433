import jwtAuthAxios from "app/services/auth/jwtAuth";
import React, { useEffect, useRef, useState } from 'react';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Card, CardContent, CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import { defaultSwalPreferences } from "app/utils/appHelpers";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import Div from "@jumbo/shared/Div";
import moment from 'moment';


const Customers = () => {
    const Swal = useSwalWrapper();
    const theme = useTheme();

    const [logsList, setLogsList] = useState([])
    const [listLoading, setListLoading] = useState(false)

    useEffect(() => {
        getLogs()
    }, [])
    
    const getLogs = () => {
        setListLoading(true)
        jwtAuthAxios.get('logs')
            .then(response => {
                setLogsList(response.data)
                setListLoading(false)
            })
            .catch((err) => {
                Swal.mixin(defaultSwalPreferences(Swal)).fire({
                    icon: 'error',
                    title: 'Error al cargar los datos',
                    background: theme.palette.background.paper,
                });
                setListLoading(false)
            });
    }

    const renderLogsList = () => {
        if(logsList){
            return (
                <React.Fragment>
                        {_.map(logsList, (l, i) => {
                            return (
                                <Grid container spacing={2} py={1}>
                                    <Grid item xs={12} md={2}>
                                        <Div sx={{display: 'flex', alignItems: 'center'}}>
                                            <CalendarMonthRoundedIcon fontSize="small" color={"text.secondary"}/>
                                            <Typography variant="body2" color={"text.secondary"}>{moment(l.created_at).format('DD MMM HH:mm:ss')}</Typography>
                                        </Div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography color={l.status == "error" ? "error" : "success.dark"}>{l.category}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <Typography>{l.data}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                </React.Fragment>
            )
        }
    }
    
    return (
        <div>
            <Typography variant="h2">Logs</Typography>

            <Div sx={{display: listLoading ? 'block' : 'none', textAlign: 'center', my: 5}}>
                <CircularProgress color="warning"/>
            </Div>
            <Card sx={{mb: 2 }}>
                <CardContent sx={{ p: 1 }}>
                    {renderLogsList()}
                </CardContent>
            </Card>
        </div>
    );
};

export default Customers;