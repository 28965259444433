import React from 'react';
import {Pagination, Stack} from "@mui/material";

const CustomPagination = props => {
    return(
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{my: 3}}>
            <Pagination
                count={props.totalPages}
                color='primary'
                page={props.currentPage}
                onChange={(event, value) => { props.onPageSelect(value) }}
            />
        </Stack>
    )
}

export default CustomPagination;